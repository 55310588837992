export default {
  data() {
    return {
    }
  },
  methods: {
    isDialogScrollable(elementDialogTop) {
      return (elementDialogTop.getElementsByClassName('v-dialog--scrollable').length > 0)
    },
    getTopActiveDialog() {
      let activeDialogs = document.getElementsByClassName('v-dialog__content--active');
      if(activeDialogs.length == 0) return null

      let activeDialog
      if(activeDialogs.length > 1) {
        let maxZ = 0
        for (let el of activeDialogs) {
          let style = window.getComputedStyle(el);
          let crrZ = style.getPropertyValue('z-index');
          if(crrZ > maxZ) {
            maxZ = crrZ
            console.log('(tmp)maxZ:', maxZ)
            activeDialog = el
            console.log('(tmp)activeDialog:', activeDialog)
          }
        }

        return activeDialog
      } else {
        return activeDialogs[0]
      }
    },
    moveToDialogTop() {
      let activeDialog = this.getTopActiveDialog()

      if(this.isDialogScrollable(activeDialog)) {
        activeDialog.getElementsByClassName('v-card__text')[0].scrollTop = 0
      } else {
        activeDialog.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
      }
    }
  }
}
